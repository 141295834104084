/* eslint-disable */
const script = document.createElement('script');
const noscript = document.createElement('noscript');
const pixel = document.createElement('img');

script.setAttribute('src', '//vk.com/js/api/openapi.js?169');
script.onload = () => {
	VK.Retargeting.Init('VK-RTRG-1004782-cdjjr')
	VK.Retargeting.Hit();
};
script.async = true;

pixel.src = 'https://vk.com/rtrg?p=VK-RTRG-1004782-cdjjr'
pixel.style.cssText = `
	position: fixed;
	left: -999px;
`;
pixel.alt = '';

noscript.appendChild(pixel);

document.head.appendChild(script);
document.head.appendChild(noscript);